<template>
      <div class="flex flex-col w-full">
        <div class="w-full bg-gray-50 text-gray-600 pb-6">
          <div class="pl-10 pt-3">
            <div class="text-xl font-bold">Mechanics</div>
            <div class="text-xs">Basic info like Make, Model, body type, colors and physical aspects of the vehicle</div>
          </div>
          <div class="mx-6 mt-2 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
            <div class="flex flex-wrap pl-2 pt-3 w-1/2">
              <toggle-checkbox v-model="lead().condition.specification.clutch"></toggle-checkbox>
              <div class="ml-4 text-blue-700 font-medium">Koppeling / Vliegwiel</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/2">
              <toggle-checkbox v-model="lead().condition.specification.timing_belt"></toggle-checkbox>
              <div class="ml-4">Recent distributie riem</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/2">
              <toggle-checkbox v-model="lead().condition.specification.recent_service"></toggle-checkbox>
              <div class="ml-4">Recent onderhoud</div>
            </div>
          </div>
          <div class="pl-10 pt-3">
            <div class="text-xl font-bold">Tyres</div>
            <div class="text-xs">Basic info like Make, Model, body type, colors and physical aspects of the vehicle</div>
          </div>
          <div class="mx-6 mt-2 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <div class="ml-4 text-blue-700 font-medium">Zomer banden</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_summer_present"></toggle-checkbox>
              <div class="ml-4">Aanwezig</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_summer_mounted"></toggle-checkbox>
              <div class="ml-4">Gemonteerd</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_summer_front"></toggle-checkbox>
              <div class="ml-4">Voor +3mm</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_summer_rear"></toggle-checkbox>
              <div class="ml-4">Achter +3mm</div>
            </div>
          </div>
          <div class="mx-6 mt-1 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <div class="ml-4 text-blue-700 font-medium">Winter banden</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_winter_present"></toggle-checkbox>
              <div class="ml-4">Aanwezig</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_winter_mounted"></toggle-checkbox>
              <div class="ml-4">Gemonteerd</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_winter_front"></toggle-checkbox>
              <div class="ml-4">Voor +3mm</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_winter_rear"></toggle-checkbox>
              <div class="ml-4">Achter +3mm</div>
            </div>
          </div>
          <div class="mx-6 mt-1 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <div class="ml-4 text-blue-700 font-medium">4 seasons banden</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_4seasons_present"></toggle-checkbox>
              <div class="ml-4">Aanwezig</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_4seasons_mounted"></toggle-checkbox>
              <div class="ml-4">Gemonteerd</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_4seasons_front"></toggle-checkbox>
              <div class="ml-4">Voor +3mm</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/5">
              <toggle-checkbox v-model="lead().condition.specification.tyres_4seasons_rear"></toggle-checkbox>
              <div class="ml-4">Achter +3mm</div>
            </div>
          </div>
          <div class="mx-6 mt-1 pl-3 pb-3 bg-white rounded-lg flex flex-wrap">
            <div class="flex flex-wrap pl-2 pt-3 w-1/4">
              <toggle-checkbox v-model="lead().condition.specification.spare_tyre"></toggle-checkbox>
              <div class="ml-4">Vol reserve wiel</div>
            </div>
          </div>
          <div class="pl-10 pt-3">
            <div class="text-xl font-bold">Interior</div>
            <div class="text-xs">Basic info like Make, Model, body type, colors and physical aspects of the vehicle</div>
          </div>
          <div class="mx-6 pl-3 mt-2 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
            <div class="flex flex-wrap pl-2 pt-3 w-1/4">
              <toggle-checkbox v-model="lead().condition.specification.keys"></toggle-checkbox>
              <div class="ml-4">Alle sleutels</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/4">
              <toggle-checkbox v-model="lead().condition.specification.airco"></toggle-checkbox>
              <div class="ml-4">Airco Werkt</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/4">
              <toggle-checkbox v-model="lead().condition.specification.interior_worn"></toggle-checkbox>
              <div class="ml-4">Slijtage sporen</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/4">
              <toggle-checkbox v-model="lead().condition.specification.smell"></toggle-checkbox>
              <div class="ml-4">geur hinder</div>
            </div>
          </div>
          <div class="pl-10 pt-3">
            <div class="text-xl font-bold">Documents</div>
            <div class="text-xs">Basic info like Make, Model, body type, colors and physical aspects of the vehicle</div>
          </div>
          <div class="mx-6 pl-3 mt-2 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
            <div class="flex flex-wrap pl-2 pt-3 w-1/4">
              <toggle-checkbox v-model="lead().condition.specification.registration_form"></toggle-checkbox>
              <div class="ml-4">Inschrijvingsbewijs</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/4">
              <toggle-checkbox v-model="lead().condition.specification.registration_form_bis"></toggle-checkbox>
              <div class="ml-4">Deel 2</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/4">
              <toggle-checkbox v-model="lead().condition.specification.conformity_form"></toggle-checkbox>
              <div class="ml-4">COC</div>
            </div>
            <div class="flex flex-wrap pl-2 pt-3 w-1/4">
              <toggle-checkbox v-model="lead().condition.specification.maintenance_book_complete"></toggle-checkbox>
              <div class="ml-4">Onderhoudshistoriek</div>
            </div>
          </div>
        </div>
      </div>

</template>

<script>
import toggleCheckbox from '@/components/toggle-checkbox'

export default {
  name: "Checks",
  components: {
    'toggle-checkbox': toggleCheckbox
  },
  data() {
    return {

    }
  },
  methods: {
    lead() {
      return this.$store.getters['seller/lead'];
    },
    selectMenu(menu) {
      this.$emit('select-detail-menu', menu);
    }
  },
  watch: {

  },
  created() {
    this.loading = false;
  },
}
</script>

<style scoped>

</style>
<template>
  <span role="checkbox" tabindex="0" :class="['relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline', value !== true && value !== false ? 'bg-gray-200' : value === true ? ' border-2 border-green-400 bg-green-400' : ' border-2 border-red-400 bg-red-400']" v-on:click="toggleValue">
      <span :class="['inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200', value === true ? 'translate-x-5' : 'translate-x-0']">
          <svg v-if="value===false" class="h-4 w-4 text-red-400" style="margin-top: 2px; margin-left: 2px; " fill="none" viewBox="0 0 12 12">
              <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <svg  v-if="value===true" class="h-4 w-4 text-green-400" style="margin-top: 2px; margin-left: 2px; " fill="currentColor" viewBox="0 0 12 12">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
       </span>
  </span>
</template>

<script>
export default {
  name: "toggle-checkbox",
  props: ['value'],
  model: {
    prop: 'value',
    event: 'input'
  },
  methods: {
    toggleValue() {
      if(this.value===undefined||this.value===null) this.value = 'tri-state';
      this.value = this.value === true ? false : this.value !== 'tri-state' ? 'tri-state' : true;
      this.$emit('input', this.value)
    }
  },
}
</script>

<style scoped>

</style>